<script setup lang="ts">
  export interface DoodleProps {
    name?: DoodleName
    random?: boolean
  }
  const props = defineProps<DoodleProps>()

  const { currentDoodlePath, getRandomDoodlePath } = useDoodleImage()

  const computedDoodlePath = computed(() =>
    props.name ? `/doodles/${props.name}.png` : currentDoodlePath.value
  )
</script>
<template>
  <img
    :src="random ? getRandomDoodlePath() : computedDoodlePath"
    alt="Doodle"
  />
</template>
