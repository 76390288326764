import { z } from 'zod'
import { useDoodleStore } from '../store/DoodleStore'
import { ref, watchEffect } from 'vue'

const allDoodles = [
  'blue_buildings',
  'books',
  'chatting',
  'city_buildings',
  'city_roads',
  'city',
  'colorfull_landscape',
  'experiments',
  'family',
  'fireworks',
  'geometric_shapes',
  'mountains',
  'outer_space',
  'painting_on_wall',
  'painting',
  'parents',
  'pets',
  'solar_system',
  'spain_clipart',
  'study',
  'sun_paintings',
  'sunshine',
  'trendy_doodle',
  'winter',
] as const

export const DoodleNameSchema = z.enum(allDoodles)
export type DoodleName = z.infer<typeof DoodleNameSchema>

export const useDoodleImage = () => {
  const doodleStore = useDoodleStore()
  const currentDoodlePath = ref('')

  const isDateChanged = (lastDoodleShowDate: Date, currentDate: Date) => {
    return (
      new Date(
        Date.UTC(
          lastDoodleShowDate.getFullYear(),
          lastDoodleShowDate.getMonth(),
          lastDoodleShowDate.getDate()
        )
      ) <
      new Date(
        Date.UTC(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        )
      )
    )
  }

  const getDoodleImagePath = (index: number) => {
    return `${useRuntimeConfig().app.cdnURL}/doodles/${allDoodles[index]}.png`
  }

  const resetOrIncrementCurrentDoodleIndex = () => {
    const currentDate = new Date()
    const lastDoodleShowDate = new Date(doodleStore.lastDoodleShowDate)
    const currentDoodleIndex = parseInt(doodleStore.currentDoodleIndex)
    if (isDateChanged(lastDoodleShowDate, currentDate)) {
      if (currentDoodleIndex >= allDoodles.length - 1) {
        doodleStore.currentDoodleIndex = '0'
      } else {
        doodleStore.currentDoodleIndex = String(currentDoodleIndex + 1)
      }
      doodleStore.doodleImage = getDoodleImagePath(
        parseInt(doodleStore.currentDoodleIndex)
      )
      doodleStore.lastDoodleShowDate = currentDate.toISOString()
    }
  }

  const initializeDoodleIndex = () => {
    if (!doodleStore.lastDoodleShowDate) {
      doodleStore.lastDoodleShowDate = new Date().toISOString()
    }
    if (
      !doodleStore.currentDoodleIndex ||
      parseInt(doodleStore.currentDoodleIndex) >= allDoodles.length ||
      parseInt(doodleStore.currentDoodleIndex) < 0 ||
      isNaN(parseInt(doodleStore.currentDoodleIndex))
    ) {
      doodleStore.currentDoodleIndex = '0'
    }
    doodleStore.doodleImage = getDoodleImagePath(
      parseInt(doodleStore.currentDoodleIndex)
    )
    resetOrIncrementCurrentDoodleIndex()
  }

  const getRandomDoodlePath = () => {
    const randomIndex = Math.floor(Math.random() * allDoodles.length)
    return getDoodleImagePath(randomIndex)
  }

  watchEffect(() => {
    initializeDoodleIndex()
    currentDoodlePath.value = doodleStore.doodleImage
  })

  return {
    currentDoodlePath,
    getRandomDoodlePath,
  }
}
